import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import BookingManagerVisibilitySettingsPage from "./bookingmanagerVisibility/displaySettings.BookingmanagerVisibility.Page";
import CalculationDisplaySettingsPage from "./calculation/displaySettings.calculation.Page";
import GettingStarted from "./displaySettings.GettingStarted";
import FareFilterSettingsPage from "./fareFilter/displaySettings.FareFilter.Page";
import RouteFilterSettingsPage from "./routeFilter/displaySettings.RouteFilter.Page";
import GeneralDisplaySettingsPage from "./general/displaySettings.general.Page";
import PassengerTypesSettingsPage from "./passengerTypes/displaySettings.passengerTypes.Page";
import PaymentDataPage from "./paymentData/displaySettings.paymentData.Page";

interface IDisplaySettingsProps {}
const DisplaySettings: React.FC<IDisplaySettingsProps> = (props) => {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/gettingstarted`} />
      </Route>
      <Route exact path={`${path}/gettingstarted`} component={GettingStarted} />
      <Route
        exact
        path={`${path}/general`}
        component={GeneralDisplaySettingsPage}
      />
      <Route
        exact
        path={`${path}/farefilter`}
        component={FareFilterSettingsPage}
      />
      <Route
        exact
        path={`${path}/routefilter`}
        component={RouteFilterSettingsPage}
      />
      <Route exact path={`${path}/paymentData`} component={PaymentDataPage} />
      <Route
        exact
        path={`${path}/calc`}
        component={CalculationDisplaySettingsPage}
      />
      <Route
        exact
        path={`${path}/passengertypes`}
        component={PassengerTypesSettingsPage}
      />
      <Route
        exact
        path={`${path}/bookingmanagervisibility`}
        component={BookingManagerVisibilitySettingsPage}
      />
      {/* <Route
        exact
        path={`${path}/smartCalendar`}
        component={smartCalender}
      /> */}
    </Switch>
  );
};

export default DisplaySettings;
