import {
  Box,
  Card,
  CardHeader,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  CardContent,
  LinearProgress,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  ApplyIndicator,
  CreateSettingsButton,
  ISettingsComponentProps,
  OverriddenIndicator,
} from "components/shared/SettingPagesVarious";
import { WebLevelLabel } from "components/shared/WebLevelLabel";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Paper from "@material-ui/core/Paper";
import { SettingsActions } from "components/shared/CardActions";
import {
  AdminEntityValidationResult,
  CreateSettingsRequest,
  SettingsOrder,
  UpdatePaymentDataSettingsModel,
} from "WebApiClient";
import { usePaymentDataSettingsClient } from "hooks/useHttpClient";
import { TransactionIDInfo } from "types/SessionTypes";
import { useDispatch } from "react-redux";
import { Session_Reducer_PushTransactionID } from "actions/SessionActions";
import { HelpTextButton } from "components/shared/various";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const paymentDataSettings: React.FC<ISettingsComponentProps> = (props) => {
  const { ReadOnly, WebLevel, Id, LevelName } = props;
  const client = usePaymentDataSettingsClient();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [inputValues, setInputValues] = useState<any>([]);
  const [finalExtractValues, setfinalExtractValues] = useState<any>([]);
  const [boolCheck, setboolCheck] = useState<any>(false);
  const [settings, setSettings] = useState<
    UpdatePaymentDataSettingsModel | any
  >(undefined);
  const [isSetting, setIsSetting] = useState(true);
  const [validationState, setValidationState] = useState<
    AdminEntityValidationResult | undefined
  >(undefined);
  const dispatch = useDispatch();

  //validationState
  const [validationBool, setValidationBool] = useState<any | undefined>([]);

  useEffect(() => {
    GetSettings(WebLevel, Id);
  }, [WebLevel, Id]);

  function DeleteSettings() {
    if (settings) {
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      setLoading(true);
      client
        .delete(settings?.id!)
        .then((response) => {
          if (response.success) {
            transactionMsg = "successfully deleted.";
            transactionSuccess = true;
          } else {
            transactionMsg = `could not be deleted. ${response.errorMessage}`;
          }
        })
        .catch((e) => {
          console.error(e);
          setServerError(true);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg = "could not be deleted. A server error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Delete Fare filter settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Fare filter settings ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          if (transactionSuccess) {
            Reload();
          }
          setLoading(false);
        });
    }
  }

  function Reload() {
    GetSettings(WebLevel, Id);
  }

  function GetSettings(order: SettingsOrder, Id: string | null) {
    setLoading(true);
    setServerError(false);
    client
      .get(order, Id)
      .then((settings) => {
        setSettings(settings);
        if (settings !== null) {
          props.OnNotifyIfSettingsExist(true);
        } else {
          props.OnNotifyIfSettingsExist(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setServerError(true);
      })
      .finally(() => {
        setboolCheck((prev: any) => !prev);
        setLoading(false);
      });
  }

  function OnUpdate() {
    setValidationBool([]);
    let result = [];
    result = finalExtractValues.map((obj: any, i: number) =>
      checkValidation(obj, i)
    );
    console.log(result)
    if (hasValueOtherThanNull(result)) {
      insertFinalValuesOnSave();
      if (settings) {
        const transactionTime = new Date().toString();
        let transactionSuccess = false;
        let transactionId = "";
        let transactionMsg = "MSG NOT SET";
        let serverSideError = false;
        setLoading(true);
        client
          .update(settings)
          .then((response) => {
            transactionId = response.transactionId;
            if (response.success) {
              transactionMsg = "successfully updated.";
              transactionSuccess = true;
            } else {
              transactionMsg = "could not be updated.";
            }
            if (response.serverError) {
              serverSideError = true;
            } else {
              setValidationState(response.validationResult);
            }
          })
          .catch((e) => {
            console.error(e);
            setServerError(true);
          })
          .finally(() => {
            if (serverSideError) {
              transactionMsg =
                "could not be updated. A serverside error has occured";
              setServerError(true);
            }
            let transaction: TransactionIDInfo = {
              Label: `Update Fare filter settings`,
              Success: transactionSuccess,
              Time: transactionTime,
              TransactionID: transactionId,
              Message: `Fare filter settings ${transactionMsg}`,
            };
            dispatch(Session_Reducer_PushTransactionID(transaction));
            if (transactionSuccess) {
              Reload();
            }
            setLoading(false);
          });
      }
    }
  }

  function CreateSettings() {
    if (Id) {
      const createRequest: CreateSettingsRequest = {
        id: Id,
        sortOrder: WebLevel,
      };
      setLoading(true);
      const transactionTime = new Date().toString();
      let transactionSuccess = false;
      let transactionId = "";
      let transactionMsg = "MSG NOT SET";
      let serverSideError = false;
      client
        .create(createRequest)
        .then((response) => {
          transactionId = response.transactionId;
          if (response.success) {
            transactionMsg = "successfully created.";
            transactionSuccess = true;
          } else {
            transactionMsg = "could not be created.";
          }
          if (response.serverError) {
            serverSideError = true;
          }
        })
        .catch((e) => {
          serverSideError = true;
          console.error(e);
        })
        .finally(() => {
          if (serverSideError) {
            transactionMsg =
              "could not be created. A serverside error has occured";
            setServerError(true);
          }
          let transaction: TransactionIDInfo = {
            Label: `Create Fare filter settings`,
            Success: transactionSuccess,
            Time: transactionTime,
            TransactionID: transactionId,
            Message: `Fare filter settings ${transactionMsg}`,
          };
          dispatch(Session_Reducer_PushTransactionID(transaction));
          setLoading(false);
          if (transactionSuccess) {
            Reload();
          }
        });
    }
  }

  //null-value Checker
  function hasValueOtherThanNull(arr: any) {
    for (const item of arr) {
      if (item !== null) {
        return false;
      }
    }
    return true;
  }

  function handleInputChange(index: number, column: string, value: any) {
    console.log('checking code value - ', index, column, value)

    setfinalExtractValues((prevInputValues: any) => {
      const newInputValues = [...prevInputValues];
      // OtherField column (string)
      newInputValues[index] = {
        ...newInputValues[index],
        [column]: value == 0 ? null : value,
      };
      return newInputValues;
    });
  }

  //nullChecker__validation
  function checkValidation(obj: any, i: number) {
    const title = obj[`title${i + 1}`];
    const code = obj[`code${i + 1}`];
    const minLen = obj[`minlen${i + 1}`];
    const maxLen = obj[`maxLen${i + 1}`];
    const fieldType = obj[`fieldType${i + 1}`];
    console.log('checking validation - ', title, code, minLen, maxLen)
    if (
      title?.length > 0 ||
      code?.length > 0 ||
      minLen?.length > 0 ||
      maxLen?.length > 0
    ) {
      if (minLen == null) {
        return null;
      }
      if (
        title == "" ||
        code == "" ||
        minLen == "" ||
        maxLen == "" ||
        title == null ||
        code == null ||
        minLen == null ||
        maxLen == null
      ) {
        setValidationBool((prevArray: any) => [...prevArray, i]);
        return i;
      }
    }
    return null;
  }

  function relevantFieldExtract() {
    const arrayOfObjects = [];
    if (settings?.id) {
      for (let i = 1; settings[`fieldType${i}`] !== undefined; i++) {
        const newObj = {
          [`title${i}`]: settings[`title${i}`]
            ? settings[`title${i}`].trim()
            : null,
          [`code${i}`]: settings[`code${i}`]
            ? settings[`code${i}`].trim()
            : null,
          [`minlen${i}`]: settings[`minlen${i}`]
            ? settings[`minlen${i}`]
            : null,
          [`maxLen${i}`]: settings[`maxLen${i}`]
            ? settings[`maxLen${i}`]
            : null,
          [`fieldType${i}`]: settings[`fieldType${i}`]
            ? settings[`fieldType${i}`].trim()
            : null,
          [`default${i}`]: settings[`default${i}`]
            ? settings[`default${i}`].trim()
            : null,
          [`visible${i}`]: settings[`visible${i}`]
            ? settings[`visible${i}`].trim()
            : null,
        };
        arrayOfObjects.push(newObj);
      }
      arrayOfObjects.map((obj: any, i: number) =>
        checkValidation(obj, i)
      )
      setfinalExtractValues(arrayOfObjects);
    }
  }

  useEffect(() => {
    relevantFieldExtract();
  }, [boolCheck]);

  function insertFinalValuesOnSave() {
    for (let i = 1; i <= finalExtractValues.length; i++) {
      const updatedFields = finalExtractValues[i - 1];
      for (const field in updatedFields) {
        settings[field] = updatedFields[field];
      }
    }
  }

  // useEffect(() => {
  //   setInputValues(
  //     arrayFromProvidedData.map((item: any) => ({
  //       code: item.code || "",
  //       min: item.min || "",
  //       max: item.max || "",
  //       type: item.type || "",
  //       projectNumber: item.projectNumber || "",
  //     }))
  //   );
  //   GetSettings(WebLevel, Id);

  //   return ()=>{}
  // }, [WebLevel, Id]);
  // console.log("final", finalExtractValues);
  const classes = useStyles();
  const [type, setType] = React.useState("");
  const handleTypeChange = (rowId: number, selectedValue: string) => {
    // Update the type state for the specified rowId
    setType((prevType) => {
      // Split the string into an array of characters
      const updatedTypes = prevType.split("");
      // Update the character at the specified index
      updatedTypes[rowId] = selectedValue;
      // Join the array back into a string
      return updatedTypes.join("");
    });
  };

  console.log("finalExtractValues", finalExtractValues);

  return (
    <Box boxShadow={ReadOnly ? undefined : 3}>
      <Card style={{ opacity: ReadOnly ? 0.5 : 1 }}>
        {/* ... (other card header and content) */}
        <CardHeader
          title={
            <span>
              {props.Applies && (
                <span>
                  <ApplyIndicator />
                </span>
              )}
              {props.IsOverwritten && (
                <span>
                  <OverriddenIndicator />
                </span>
              )}
              <span>Payment Data settings for </span>
              <WebLevelLabel Level={WebLevel} Name={LevelName} />
              <HelpTextButton HelpText={SettingsHierarchieHelpText} />
            </span>
          }
        />
        {!loading && (
          <>
            <CardContent
              style={{ margin: "0 auto", width: "50%", alignItems: "center" }}
            >
              {settings?.id && !serverError ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <TableContainer
                      style={{ width: "100%", alignItems: "center" }}
                      component={Paper}
                    >
                      <Table
                        style={{ width: "100%" }}
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Min</TableCell>
                            <TableCell>Max</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Default value</TableCell>
                            <TableCell>Field name</TableCell>
                            <TableCell>Visible</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {finalExtractValues?.map(
                            (row: any, index: number) => (
                              <TableRow key={"finalExtractValues" + index}>
                                <TableCell>
                                  <input
                                    style={{ width: "50px", height: "30px" }}
                                    className={
                                      validationBool.includes(index) &&
                                        (finalExtractValues[index][
                                          `code${index + 1}`
                                        ]?.length == null ||
                                          finalExtractValues[index][
                                            `code${index + 1}`
                                          ]?.length == "") &&
                                        (finalExtractValues[index][
                                          `code${index + 1}`
                                        ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `minlen${index + 1}`
                                          ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `maxLen${index + 1}`
                                          ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `title${index + 1}`
                                          ]?.length > 0)
                                        ? `square-input invalidInput`
                                        : `square-input`
                                    }
                                    id="square-input"
                                    type="text"
                                    value={
                                      finalExtractValues[index][
                                      `code${index + 1}`
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        `code${index + 1}`,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    style={{ width: "50px", height: "30px" }}
                                    className={
                                      validationBool.includes(index) &&
                                        (finalExtractValues[index][
                                          `minlen${index + 1}`
                                        ] == null ||
                                          finalExtractValues[index][
                                            `minlen${index + 1}`
                                          ]?.length == "") &&
                                        (finalExtractValues[index][
                                          `code${index + 1}`
                                        ]?.length > 0 ||
                                          finalExtractValues[index][
                                          `minlen${index + 1}`
                                          ] != null ||
                                          finalExtractValues[index][
                                          `maxLen${index + 1}`
                                          ] != null ||
                                          finalExtractValues[index][
                                            `title${index + 1}`
                                          ]?.length > 0)
                                        ? `square-input invalidInput`
                                        : `square-input`
                                    }
                                    id="square-input"
                                    type="text"
                                    value={
                                      finalExtractValues[index][
                                      `minlen${index + 1}`
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        `minlen${index + 1}`,
                                        Number(e.target.value)
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <input
                                    style={{ width: "50px", height: "30px" }}
                                    className={
                                      validationBool.includes(index) &&
                                        (finalExtractValues[index][
                                          `maxLen${index + 1}`
                                        ]?.length == null ||
                                          finalExtractValues[index][
                                            `maxLen${index + 1}`
                                          ]?.length == "") &&
                                        (finalExtractValues[index][
                                          `code${index + 1}`
                                        ]?.length > 0 ||
                                          finalExtractValues[index][
                                          `minlen${index + 1}`
                                          ] != null ||
                                          finalExtractValues[index][
                                          `maxLen${index + 1}`
                                          ] != null ||
                                          finalExtractValues[index][
                                            `title${index + 1}`
                                          ]?.length > 0)
                                        ? `square-input invalidInput`
                                        : `square-input`
                                    }
                                    type="text"
                                    value={
                                      finalExtractValues[index][
                                      `maxLen${index + 1}`
                                      ]!
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        `maxLen${index + 1}`,
                                        Number(e.target.value)
                                      )
                                    }
                                  />
                                </TableCell>
                                <InputType
                                  rowId={index}
                                  handleTypeChange={handleTypeChange}
                                  finalExtractValues={finalExtractValues}
                                  handleInputChange={handleInputChange}
                                />
                                <TableCell component="th" scope="row">
                                  <input
                                    style={{ height: "30px" }}
                                    className={
                                      validationBool.includes(index) &&
                                        (finalExtractValues[index][
                                          `title${index + 1}`
                                        ]?.length == null ||
                                          finalExtractValues[index][
                                            `title${index + 1}`
                                          ]?.length == "") &&
                                        (finalExtractValues[index][
                                          `code${index + 1}`
                                        ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `minlen${index + 1}`
                                          ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `maxLen${index + 1}`
                                          ]?.length > 0 ||
                                          finalExtractValues[index][
                                            `title${index + 1}`
                                          ]?.length > 0)
                                        ? `square-input invalidInput`
                                        : `square-input`
                                    }
                                    type="text"
                                    value={
                                      finalExtractValues[index][
                                      `title${index + 1}`
                                      ]!
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        `title${index + 1}`,
                                        e.target.value
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    style={{ width: "50px", height: "30px" }}
                                    // type="text"
                                    checked={
                                      finalExtractValues[index][
                                      `visible${index + 1}`
                                      ] === "1"
                                    }
                                    onChange={(e) => {
                                      handleInputChange(
                                        index,
                                        `visible${index + 1}`,
                                        e.target.checked ? "1" : "0"
                                      );
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              ) : (
                <CreateSettingsButton
                  OnClick={CreateSettings}
                  Readonly={props.ReadOnly}
                />
              )}
            </CardContent>
          </>
        )}
        {loading && (
          <CardContent>
            <LinearProgress />
          </CardContent>
        )}
        {!ReadOnly && settings && isSetting && (
          <SettingsActions
            OnDelete={DeleteSettings}
            OnReset={Reload}
            OnSave={OnUpdate}
          />
        )}
      </Card>
    </Box>
  );
};

interface InputTypeProps {
  rowId: number;
  handleTypeChange: (rowId: number, selectedValue: string) => void;
  handleInputChange: (rowId: number, fieldName: string, value: string) => void;
  finalExtractValues: any; // Adjust the type accordingly
}

export const InputType: React.FC<InputTypeProps> = ({
  rowId,
  handleTypeChange,
  finalExtractValues,
  handleInputChange,
}) => {
  const [type, setType] = useState<string>(
    finalExtractValues[rowId][`fieldType${rowId + 1}`] == null ? "0" : finalExtractValues[rowId][`fieldType${rowId + 1}`]
  );

  const handleChange = (event: ChangeEvent<{ value: any }>) => {
    const selectedValue = event.target.value as string;
    setType(selectedValue);
    handleTypeChange(rowId, selectedValue);
    handleInputChange(rowId, `fieldType${rowId + 1}`, event.target.value);
  };
  // console.log(
  //   "finalExtractValues[rowId][`default${rowId + 1}`]",
  //   finalExtractValues[rowId][`default${rowId + 1}`]
  // );

  const renderInput = () => {
    switch (type) {
      case "0":
        return (
          <input
            type="text"
            style={{ height: "30px" }}
            value={finalExtractValues[rowId][`default${rowId + 1}`]}
            onChange={(e) =>
              handleInputChange(rowId, `default${rowId + 1}`, e.target.value)
            }
          />
        );
      case "1":
        return (
          <input
            type="number"
            style={{ height: "30px" }}
            value={finalExtractValues[rowId][`default${rowId + 1}`]}
            onChange={(e) =>
              handleInputChange(rowId, `default${rowId + 1}`, e.target.value)
            }
          />
        );
      case "2":
        return (
          <textarea
            rows={5}
            // style={{ height: "30px" }}
            value={finalExtractValues[rowId][`default${rowId + 1}`]}
            onChange={(e) =>
              handleInputChange(rowId, `default${rowId + 1}`, e.target.value)
            }
          />
        );
      default:
        return (
          <input
            type="text"
            style={{ height: "30px" }}
            value={finalExtractValues[rowId][`default${rowId + 1}`]}
            onChange={(e) =>
              handleInputChange(rowId, `default${rowId + 1}`, e.target.value)
            }
          />
        );
    }
  };

  return (
    <React.Fragment>
      <TableCell key="input-type">
        <FormControl style={{ margin: 1, minWidth: 120 }} size="small">
          <InputLabel id={`demo-select-small-label-${rowId}`}>
            Input Type
          </InputLabel>
          <Select
            labelId={`demo-select-small-label-${rowId}`}
            id={`demo-select-small-${rowId}`}
            value={type}
            label="Input Type"
            onChange={handleChange}
          >
            <MenuItem value="0">String</MenuItem>
            <MenuItem value="1">Numeric</MenuItem>
            <MenuItem value="2">Dropdown</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell key="render-input">{renderInput()}</TableCell>
    </React.Fragment>
  );
};


const SettingsHierarchieHelpText = <div>
  <p>
    <b>Web hierarchies</b>
  </p>

  <ul>
    <li>
      You can assign the settings on this page indepentently.<br></br>
      <br></br>
      The settings will be applied from Branch to Web. That means that a Branch setting will overwrite an Branchgroup setting and a Branchgroup setting will overwrite a Web setting.
      <br></br>
      If you want to configure a setting for example for all Branches in a Branchgroup, create settings on the Branchgroup level and leave the configuration in the Branches empty.
    </li>
  </ul>

  <ul>
    <li>RM/ any other Code = default Payment remark - Invoice</li>
    <li>RMNO = None</li>
    <li>RMGE = General</li>
    <li>RMHI = Historical</li>
    <li>RMCD = Coded</li>
    <li>RMCO = Confidential</li>
    <li>RMIT = Itinerary</li>
    <li>RMIN = Invoice</li>
    <li>RMOT = Other</li>
  </ul>


</div>
export default paymentDataSettings;
