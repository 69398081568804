import { Divider } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AppsIcon from "@material-ui/icons/Apps";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import FilterListIcon from "@material-ui/icons/FilterList";
import Payment from "@material-ui/icons/Payment";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import GetAppIcon from "@material-ui/icons/GetApp";
import GroupIcon from "@material-ui/icons/Group";
import HelpIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ImageIcon from "@material-ui/icons/Image";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PublishIcon from "@material-ui/icons/Publish";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import StarsIcon from "@material-ui/icons/Stars";
import WebIcon from "@material-ui/icons/Web";
import React from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { NavbarArea, NavBarAreaProps } from "./Navbar.Area";
import { RoomTwoTone } from "@material-ui/icons";
export const NavbarPaths: React.FC<{}> = () => {
  const IsAdmin: boolean = useSelector((state: State) => state.Session.IsAdmin);
  const IsHitchHiker: boolean = useSelector(
    (state: State) => state.Session.IsHitchHiker
  );
  const areas: NavBarAreaProps[] = [
    {
      AreaIcon: <HomeIcon />,
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
      ],
      RootPath: "home",
      Title: "Home",
    },
    {
      AreaIcon: <DashboardIcon />,
      RootPath: "masterdata",
      Title: "Master data",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <AppsIcon />,
          Path: "branchgroups",
          Title: "Branchgroups",
        },
        {
          Icon: <AccountTreeIcon />,
          Path: "branches",
          Title: "Branches",
        },
        {
          Icon: <GroupIcon />,
          Path: "users",
          Title: "Users",
        },
        {
          Icon: <BuildIcon />,
          Path: "multichannel",
          Title: "Multichannel configuration",
        },
      ],
    },
    {
      AreaIcon: <WebIcon />,
      RootPath: "displaysettings",
      Title: "Display settings",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <FeaturedVideoIcon />,
          Path: "general",
          Title: "General settings",
        },
        {
          Icon: <FilterListIcon />,
          Path: "farefilter",
          Title: "Fare filter",
        },
        {
          Icon: <RoomTwoTone />,
          Path: "routefilter",
          Title: "Route filter",
        },
        {
          Icon: <PeopleAltIcon />,
          Path: "passengertypes",
          Title: "Passenger types",
        },
        {
          Icon: <Payment />,
          Path: "paymentData",
          Title: "Payment Data",
        },
        {
          Icon: <AttachMoneyIcon />,
          Path: "calc",
          Title: "Calculation",
        },
        {
          Icon: <ListAltIcon />,
          Path: "bookingmanagervisibility",
          Title: "Booking manager",
        },
        // {
        //     Icon: <ListAltIcon />,
        //     Path: "smartCalendar",
        //     Title: "Smart calendar"
        // },
      ],
    },
    {
      AreaIcon: <BusinessIcon />,
      RootPath: "corporateidentity",
      Title: "Corporate Identity",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <ImageIcon />,
          Path: "logos",
          Title: "Logos",
        },
        {
          Icon: <HourglassEmptyIcon />,
          Path: "waitscreen",
          Title: "Waitscreen",
        },
        {
          Icon: <ImageIcon />,
          Path: "emailTemplates",
          Title: "Email Templates",
        },
      ],
    },
    {
      AreaIcon: <GetAppIcon />,
      RootPath: "export",
      Title: "Export",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <LockOpenIcon />,
          Path: "deeplinks",
          Title: "Deeplinks",
        },
      ],
    },
    {
      AreaIcon: <PublishIcon />,
      RootPath: "import",
      Title: "Import",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <DashboardIcon />,
          Path: "branches",
          Title: "Branches",
        },
        {
          Icon: <GroupIcon />,
          Path: "users",
          Title: "Users",
        },
      ],
    },
    {
      AreaIcon: <MonetizationOnIcon />,
      RootPath: "calculation",
      Title: "Calculation",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <FormatListNumberedIcon />,
          Path: "rules",
          Title: "Rules",
        },
        {
          Icon: <PlayCircleOutlineIcon />,
          Path: "formulas",
          Title: "Formulas",
        },
        {
          Icon: <EuroSymbolIcon />,
          Path: "currencies",
          Title: "Currencies",
        },
        {
          Icon: <AccountBalanceWalletIcon />,
          Path: "consolidator",
          Title: "Consolidator calculation",
        },
      ],
    },
    {
      AreaIcon: <StarsIcon />,
      RootPath: "salespreference",
      Title: "Sales preference",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <FormatListNumberedIcon />,
          Path: "rules",
          Title: "Rules",
        },
        {
          Icon: <PlayCircleOutlineIcon />,
          Path: "actions",
          Title: "Actions",
        },
      ],
    },
    {
      AreaIcon: <AccountBalanceWalletIcon />,
      RootPath: "budgeting",
      Title: "Budgeting",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <LocalAtmIcon />,
          Path: "deposits",
          Title: "Deposits",
        },
      ],
    },
    {
      AreaIcon: <QueryBuilderIcon />,
      RootPath: "universaltempdata",
      Title: "Temporal data",
      Links: [
        {
          Icon: <HelpIcon />,
          Path: "gettingstarted",
          Title: "Getting started",
        },
        {
          Icon: <ListIcon />,
          Path: "customerprofiles/list",
          Title: "Customer profiles",
        },
      ],
    },
  ];
  return (
    <div>
      {areas.map((area, index) => (
        <div key={index}>
          <NavbarArea {...area} />
          <Divider />
        </div>
      ))}
    </div>
  );
};
